import { template as template_97701d9f2c344453bdecbd8e7d586281 } from "@ember/template-compiler";
const FKControlMenuContainer = template_97701d9f2c344453bdecbd8e7d586281(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
