import { template as template_45a420d0daa940b3987e4c18abfb16a9 } from "@ember/template-compiler";
const WelcomeHeader = template_45a420d0daa940b3987e4c18abfb16a9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
