import { template as template_8a0faa2c9eb24de2844739ed7297a158 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_8a0faa2c9eb24de2844739ed7297a158(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
