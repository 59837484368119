import { template as template_d2b1984171f64176b6d9af62867b78f5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_d2b1984171f64176b6d9af62867b78f5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
