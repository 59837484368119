import { template as template_2fd896f7a1824fe9a8d1f5737c52816a } from "@ember/template-compiler";
const FKLabel = template_2fd896f7a1824fe9a8d1f5737c52816a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
