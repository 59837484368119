import { template as template_32dca643b2634f86818d2915c94080af } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_32dca643b2634f86818d2915c94080af(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
